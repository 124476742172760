.sidebar {
  position: fixed;
  top: 0;
  left: 0;

  width: 300px;
  max-width: 80%;
  height: 100vh;

  background-color: #{"rgb(var(--gray-0))"};
  border-right: 1px solid #{"rgb(var(--gray-100))"};

  display: flex;
  flex-direction: column;

  transition: all 0.3s;

  @media (max-width: 1150px) {
    z-index: 99999;
    transform: translateX(-100%);

    &.open {
      transform: translateX(0);
    }
  }

  &__overlay {
    @media (min-width: 1150px) {
      display: none !important;
    }
  }

  &__header {
    flex: 0 0 7.2rem;

    padding: 0 2.4rem;
    display: flex;
    align-items: center;

    & svg {
      display: block;
      height: 2.8rem;
    }
  }

  &__main {
    flex: 1;

    padding: 2.4rem 0;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__nav {
    list-style: none;

    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__nav-heading {
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0 3.6rem;
    font-size: 1.2rem;
    margin-bottom: 4px;
    color: #{"rgb(var(--gray-500))"};
    font-family: "Lexend", sans-serif;
  }

  &__nav-item {
    padding: 0 2.4rem;

    position: relative;
  }

  &__link {
    &:link,
    &:visited {
      text-decoration: none;
      font-weight: 500;
      color: #{"rgb(var(--gray-700))"};

      display: flex;
      align-items: center;
      gap: 0.8rem;

      padding: 8px 12px;
      border-radius: 6px;

      transition: all 0.3s;
    }

    &:hover,
    &:active {
      background-color: #{"rgb(var(--gray-100))"};
      color: #{"rgb(var(--gray-900))"};
    }

    &.active {
      color: #{"rgb(var(--primary-default))"} !important;
    }

    &::before {
      content: "";
      display: block;

      position: absolute;
      top: 5px;
      left: 0;
      width: 4px;
      height: 2.8rem;

      background-color: #{"rgb(var(--primary-default))"};
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      opacity: 0;

      transition: all 0.3s;
    }

    &.active::before {
      opacity: 1;
    }
  }

  &__link-img {
    display: block;
    width: 2rem;
    height: 2rem;
  }

  &__link-text {
    margin-right: auto;
  }

  &__link-badge {
    padding: 1px 8px;
    border-radius: 10rem;
    font-size: 1.2rem;
    font-family: "Lexend", sans-serif;

    &--red {
      color: #{"rgb(var(--red-dark))"};
      border: 1px solid #{"rgb(var(--red-default))"};
      background-color: #{"rgb(var(--red-lighter))"};
    }

    &--green {
      color: #{"rgb(var(--green-dark))"};
      border: 1px solid #{"rgb(var(--green-default))"};
      background-color: #{"rgb(var(--green-lighter))"};
    }
  }

  &__link-icon {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    color: #{"rgb(var(--gray-500))"};

    transition: all 0.3s;
  }

  &__link.open &__link-icon {
    transform: rotate(90deg);
  }

  &__submenu {
    list-style: none;

    display: flex;
    flex-direction: column;
    gap: 2px;

    // come back to
    max-height: 0;
    overflow-y: hidden;

    transition: max-height 0.3s ease;
  }

  &__sub-link {
    &:link,
    &:visited {
      text-decoration: none;
      font-weight: 500;
      color: inherit;

      display: flex;
      align-items: center;
      gap: 0.8rem;

      padding: 8px 20px;
      border-radius: 6px;
      color: #{"rgb(var(--gray-500))"};

      transition: all 0.3s;
    }

    &:hover,
    &:active {
      background-color: #{"rgb(var(--gray-100))"};
      color: #{"rgb(var(--gray-900))"};
    }

    &.active {
      color: #{"rgb(var(--primary-default))"} !important;
    }

    &::before {
      content: "";
      display: block;

      width: 4px;
      height: 4px;
      background-color: currentColor;
      border-radius: 50%;
      background-color: #{"rgb(var(--gray-500))"};

      transition: all 0.3s;
    }

    &.active::before {
      width: 6px;
      height: 6px;

      background-color: #{"rgb(var(--primary-default))"} !important;
    }
  }
}
