.dashboard {
  padding-left: 300px;

  @media (max-width: 1150px) {
    padding-left: 0;
  }
}

.main {
  // padding: 2.4rem;

  // &__section {
  // }
}

.info-grids {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.4rem;

  @media (max-width: 1700px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1350px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.info-grid {
  padding: 3rem 2.4rem;
  border: 1px solid #{"rgb(var(--gray-300))"};
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__main {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  &__icon-block {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 5rem;
    height: 5rem;
    border-radius: 1.4rem;

    &--1 {
      background-color: #8a63d2;
    }

    &--2 {
      background-color: #00cec9;
    }

    &--3 {
      background-color: #0070f3;
    }

    &--4 {
      background-color: #f5a623;
    }

    & ion-icon {
      display: block;
      width: 3rem;
      height: 3rem;
      color: #fff;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__info-key {
    color: #{"rgb(var(--gray-500))"};
  }

  &__info-value {
    font-family: "Lexend", sans-serif;
    font-weight: 600;
    font-size: 2rem;
    color: #{"rgb(var(--gray-900))"};
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__diff {
    display: flex;
    align-items: center;
    gap: 4px;

    & span {
      display: inline-flex;
      padding: 6px 1.2rem;
      border-radius: 10rem;

      & ion-icon {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    &--up {
      color: #{"rgb(var(--green-dark))"};

      & span {
        background-color: #{"rgb(var(--green-lighter))"};

        & ion-icon {
          transform: rotate(45deg);
        }
      }
    }

    &--down {
      color: #{"rgb(var(--red-dark))"};

      & span {
        background-color: #{"rgb(var(--red-lighter))"};

        & ion-icon {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.8rem;

  padding: 2.4rem;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: stretch;
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 1.8rem;

    @media (max-width: 400px) {
      & > * {
        flex: 1;
      }
    }
  }

  &__heading {
    font-size: 2.6rem;
    font-weight: 700;
    font-family: "Lexend", sans-serif;
    color: #{"rgb(var(--gray-900))"};
  }

  &__breadcrumb {
    list-style: none;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.2rem;
  }

  &__breadcrumb-item {
    &:not(:first-child) {
      display: flex;
      align-items: center;
      gap: 1.2rem;

      &::before {
        content: "";
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #{"rgb(var(--gray-300))"};
      }
    }

    & a {
      &:link,
      &:visited {
        text-decoration: none;
        font-weight: 500;
        color: #{"rgb(var(--gray-700))"};
      }
    }

    & span {
      font-weight: 500;
      color: #{"rgb(var(--gray-500))"};
    }
  }
}
