.btn-footer {
  height: 7.2rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.8rem;

  padding: 0 2.4rem;
  background-color: #fff;
  border-top: 1px solid #{"rgb(var(--gray-200))"};

  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 999;

  transition: all 0.3s;

  @media (max-width: 450px) {
    & > * {
      flex: 1;
    }
  }

  .modal-open & {
    position: relative;
  }
}
