.auth {
  padding: 2.4rem;

  display: flex;
  align-items: center;
  gap: 2.4rem;

  @media (max-width: 1150px) {
    min-height: calc(100vh - 4.8rem);

    justify-content: center;
    align-items: center;
  }

  &__main {
    flex: 0 0 56rem;

    padding: 5.6rem;

    @media (max-width: 1150px) {
      flex: initial;
      width: 56rem;
      max-width: 100%;
    }

    @media (max-width: 400px) {
      padding: 0;
    }
  }

  &__logo-block {
    display: flex;
    align-items: center;

    margin-bottom: 3.6rem;

    @media (max-width: 1150px) {
      justify-content: center;
    }
  }

  &__logo {
    display: block;
    height: 3.8rem;
  }

  &__header {
    margin-bottom: 2.4rem;

    display: flex;
    flex-direction: column;
    gap: 6px;

    @media (max-width: 1150px) {
      text-align: center;
    }
  }

  &__heading {
    font-size: 2.8rem;
    font-weight: 700;
    font-family: "Lexend", sans-serif;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__form-footer {
    margin-top: 2.4rem;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__footer {
    margin-top: 4.2rem;

    @media (max-width: 1150px) {
      text-align: center;
    }

    & a {
      &:link,
      &:visited {
        color: inherit;
        font-weight: 500;
        text-decoration: none;
      }
    }
  }

  &__right {
    flex: 1;

    padding: 6.4rem;
    background-color: #{"rgb(var(--gray-50))"};
    border-radius: 1.2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;

    text-align: center;

    min-height: calc(100vh - 4.8rem);

    @media (max-width: 1150px) {
      display: none;
    }
  }

  &__right-heading {
    font-size: 2.6rem;
    font-weight: 600;
    font-family: "Lexend", sans-serif;
  }

  &__right-img {
    display: block;
    max-width: 100%;
  }
}
