.order-details {
  padding: 0 2.4rem 3.6rem;

  &__summary {
    padding: 1.6rem 0;
    border-top: 1px solid #{"rgb(var(--gray-300))"};
    border-bottom: 1px solid #{"rgb(var(--gray-300))"};
    font-weight: 500;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > * {
      border-right: 1px solid #{"rgb(var(--muted))"};
      padding: 2px 2rem;
      margin: 6px 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border-right: none;
      }
    }

    @media (max-width: 800px) {
      justify-content: center;
    }
  }

  &__content {
    padding-top: 4rem;

    display: flex;
    gap: 2.8rem;

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  &__main {
    flex: 1;
  }

  &__right {
    width: 45rem;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    @media (max-width: 1400px) {
      width: 35rem;
    }

    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;

    &--lg {
      gap: 2rem;
    }
  }

  &__block-heading {
    font-weight: 600;
    font-family: "Lexend", sans-serif;
    color: #{"rgb(var(--gray-900))"};
    font-size: 1.6rem;
  }

  &__user {
    display: flex;
    align-items: flex-start;
    gap: 1.6rem;

    padding: 2rem;
    border-radius: 6px;
    border: 1px solid #{"rgb(var(--muted))"};
  }

  &__user-img {
    display: block;
    width: 6.4rem;
    height: 6.4rem;
    border-radius: 50%;
  }

  &__user-name {
    font-size: 1.6rem;
    font-weight: 600;
    font-family: "Lexend", sans-serif;
    color: #{"var(--gray-900)"};
    margin-bottom: 1rem;
    line-height: 1.5rem;
  }

  &__user-email {
    margin-bottom: 6px;
  }

  &__user-phone {
  }

  &__empty {
    padding: 2rem;
    border-radius: 6px;
    border: 1px solid #{"rgb(var(--muted))"};

    display: flex;
    flex-direction: column;
    gap: 4px;

    letter-spacing: 2px;
  }

  &__progresses {
    padding: 2rem;
    border-radius: 6px;
    border: 1px solid #{"rgb(var(--muted))"};
  }

  &__transactions {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__transaction {
    border: 1px solid #{"rgb(var(--gray-100))"};
    padding: 2rem;
    border-radius: 6px;

    display: flex;
    align-items: center;
    gap: 1.6rem;
  }

  &__transaction-img {
    display: block;
    width: 6rem;
  }

  &__transaction-main {
    display: flex;
    flex-direction: column;
  }

  &__transaction-heading {
    font-weight: 400;
    font-family: "Lexend", sans-serif;
    color: #{"rgb(var(--gray-700))"};
  }

  &__transaction-sub {
    font-size: 1.3rem;
    font-weight: 400;
    color: #{"rgb(var(--gray-500))"};
  }

  &__transaction-price {
    margin-left: auto;
    font-weight: 500;
  }

  &__balance {
    padding: 2.8rem;
    border: 1px solid #{"rgb(var(--muted))"};
    font-weight: 500;
    border-radius: 1.2rem;

    display: flex;
    flex-direction: column;
    gap: 2.8rem;

    & > * {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.order-summary {
  margin-top: 1.2rem;
  border-top: 1px solid #{"rgb(var(--muted))"};
  padding-top: 2.8rem;

  &__main {
    max-width: 50rem;
    margin-left: auto;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-weight: 500;

      &:not(:first-child) {
        margin-top: 2.4rem;
      }

      &:last-child {
        padding-top: 2rem;
        border-top: 1px solid #{"rgb(var(--muted))"};
        font-weight: 600;
        font-size: 1.6rem;
      }
    }
  }
}

.order-progress {
  display: flex;
  align-items: flex-end;
  gap: 1.2rem;

  font-weight: 500;
  font-size: 1.35rem;

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__line {
    width: 3px;
    height: 2.6rem;
    background-color: #{"rgb(var(--gray-100))"};
  }

  &.done &__line {
    background-color: #{"rgb(var(--primary-default))"};
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #{"rgb(var(--gray-100))"};
    border: none;

    & svg {
      display: block;
      opacity: 0;
      color: #fff;
      width: 1.2rem;
      height: 1.2rem;

      transition: all 0.3s;
    }
  }

  &.done &__button {
    background-color: #{"rgb(var(--primary-default))"};

    & svg {
      opacity: 1;
    }
  }

  &__main {
    flex: 1;
  }
}
