:root {
  --gray-0: 255 255 255;
  --gray-50: 250 250 250;
  --gray-100: 241 241 241;
  --gray-200: 227 227 227;
  --gray-300: 223 223 223;
  --gray-400: 146 146 146;
  --gray-500: 102 102 102;
  --gray-600: 72 72 72;
  --gray-700: 51 51 51;
  --gray-800: 34 34 34;
  --gray-900: 17 17 17;
  --gray-1000: 0 0 0;

  --background: 255 255 255;
  --foreground: 72 72 72;

  --muted: 227 227 227;
  --muted-foreground: 146 146 146;

  --primary-dark: 29 88 216;
  --primary-light: 96 142 251;
  --primary-lighter: 215 227 254;
  --primary-default: 56 114 250;
  --primary-foreground: 255 255 255;

  --secondary-lighter: 221 227 255;
  --secondary-default: 78 54 245;
  --secondary-dark: 67 42 216;
  --secondary-foreground: 255 255 255;

  --red-lighter: 247 212 214;
  --red-default: 238 0 0;
  --red-dark: 197 0 0;

  --orange-lighter: 255 239 207;
  --orange-default: 245 166 35;
  --orange-dark: 171 87 10;

  --blue-lighter: 211 229 255;
  --blue-default: 0 112 243;
  --blue-dark: 7 97 209;

  --green-lighter: 185 249 207;
  --green-default: 17 168 73;
  --green-dark: 17 132 60;
}
