.form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &--centered {
    justify-content: center;
    text-align: center;
  }
}

.form-label {
  font-weight: 500;
}

.form-input {
  height: 4.8rem;
  font-family: inherit;
  border: 1px solid #{"rgb(var(--muted))"};
  border-radius: 6px;
  padding: 0 1.6rem;
  font-size: inherit;
}

.form-input-group {
  position: relative;

  display: flex;
  flex-direction: column;

  & input {
    padding-right: 6.4rem;
  }

  & button {
    position: absolute;
    top: 0;
    right: 0;
    width: 4.8rem;
    height: 100%;
    z-index: 9;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background-color: transparent;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.pin-input-group {
  display: flex;
  justify-content: center;
  gap: 6px;
}

.pin-input {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  flex-shrink: 0;
  width: 4.8rem;
  height: 4.8rem;
  font-family: inherit;
  border: 1px solid #{"rgb(var(--muted))"};
  border-radius: 6px;
}

.search-box {
  position: relative;

  width: 28rem;
  height: 4rem;

  &--md {
    height: 4.4rem;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 1.2rem;
    transform: translateY(-50%);

    display: block;
    width: 1.6rem;
    height: 1.6rem;
  }

  &__input {
    height: 4rem !important;
    padding-left: 3.6rem !important;
  }

  &--md &__input {
    height: 4.4rem !important;
  }

  &--filled &__input {
    padding-right: 3.6rem !important;
  }

  &__clear {
    position: absolute;
    top: 50%;
    right: 1.1rem;
    transform: translateY(-50%);

    cursor: pointer;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #{"rgb(var(--muted)/0.7)"};

    opacity: 0;
    pointer-events: none;

    transition: all 0.3s;

    & ion-icon {
      color: #{"rgb(var(--foreground)/.9)"};
    }
  }

  &--filled &__clear {
    opacity: 1;
    pointer-events: all;
  }
}

.form-tab {
  padding: 0 2.4rem;

  background-color: #fff;

  position: sticky;
  top: 7.2rem;
  z-index: 99;

  .modal-open & {
    position: relative;
    top: initial;
  }

  &__main {
    list-style: none;

    height: 5.2rem;
    border-bottom: 1px solid #{"rgb(var(--gray-300))"};

    display: flex;
    gap: 3.6rem;

    overflow-x: auto;
  }

  &__item {
    flex-shrink: 0;

    display: flex;
  }

  &__link {
    outline: none;

    &::after {
      content: "";
      display: block;

      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #{"rgb(var(--gray-1000))"};
      opacity: 0;

      transition: all 0.3s;
    }

    &:link,
    &:visited {
      display: flex;
      align-items: center;
      position: relative;

      padding: 0 6px;

      text-decoration: none;
      color: #{"rgb(var(--gray-500))"};
      font-weight: 500;
      white-space: nowrap;

      transition: all 0.3s;
    }

    &:focus span {
      outline-width: 2px;
      outline-style: solid;
      border-radius: 3px;
      outline-offset: 2px;
    }

    &:hover {
      color: #{"rgb(var(--gray-1000))"};
    }

    &:active,
    &.active {
      color: #{"rgb(var(--gray-1000))"} !important;
      font-weight: 600 !important;

      & span {
        outline: none !important;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}

.stepped-form-container {
  display: flex;
  flex-direction: column;
  gap: 4.8rem;

  padding: 4.8rem 2.4rem 0;

  &--1 {
    padding-top: 0;
  }
}

.stepped-form {
  display: grid;
  grid-template-columns: 30fr 60fr;
  gap: 5.6rem;

  @media (max-width: 1050px) {
    gap: 2.4rem;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  &:not(:first-child) {
    padding-top: 4.8rem;
    border-top: 1px dashed #{"rgb(var(--gray-200))"};
  }

  &:last-child {
    padding-bottom: 4.8rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__heading {
    font-family: "Lexend", sans-serif;
    font-weight: 500;
    color: #{"rgb(var(--gray-900))"};
  }

  // &__subheading {
  // }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.8rem;
    row-gap: 2.4rem;

    &--3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &--4 {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  &__flex {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__custom-flex {
    display: flex;
    align-items: flex-end;
    gap: 3.2rem;

    @media (max-width: 450px) {
      gap: 2.4rem;
    }
  }
}

.form-card {
  padding: 2.4rem;
  border-radius: 8px;
  border: 1px solid #{"rgb(var(--muted))"};
}

.fgroup {
  // flex: 1;

  display: flex;
  flex-direction: column;
  gap: 4px;
}

.range-group {
  display: flex;
  align-items: center;
  gap: 8px;

  &__divider {
    height: 2px;
    background-color: #{"rgb(var(--muted))"};
    width: 1.6rem;
  }
}

.label {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  font-weight: 500;
}

.input {
  height: 4.4rem;
  border-radius: 6px;
  font-family: inherit;
  font-size: inherit;
  border: 2px solid #{"rgb(var(--muted))"};
  outline: 1px solid #{"rgb(var(--muted))"};
  outline-offset: -2px;
  padding: 0 1.2rem;
  width: 100%;

  &--sm {
    height: 3.6rem;
    font-size: 1.2rem;
    outline: none !important;
  }

  &::placeholder {
    font-weight: 500;
    color: #{"rgb(var(--muted-foreground))"};
  }

  display: flex;
  align-items: center;

  transition: all 0.3s;

  &:hover {
    outline: 1px solid #{"rgb(var(--primary-default))"};
  }

  &:focus {
    outline: 2px solid #{"rgb(var(--primary-default))"};
  }

  &:disabled {
    background-color: #{"rgb(var(--muted) / .7)"};
    cursor: not-allowed;
    outline: none !important;
  }
}

textarea.input {
  height: auto;
  padding: 1.2rem;
}

.igroup {
  position: relative;

  display: flex;
  flex-direction: column;

  & input {
    padding-left: 4rem;
  }

  &--right input {
    padding-left: 1.2rem;
    padding-right: 4rem;
  }

  & span {
    position: absolute;
    top: 0;
    left: 0;
    width: 4.4rem;
    height: 100%;
    z-index: 9;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background-color: transparent;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: #{"rgb(var(--gray-500))"};
    }
  }

  &--right span {
    left: initial;
    right: 0;
  }
}

.select {
  position: relative;
}

.select-input {
  position: relative;

  & input {
    padding-right: 4rem;
    cursor: pointer;
    caret-color: transparent;
  }

  &__icon-box {
    position: absolute;
    top: 0;
    right: 0;
    width: 4.4rem;
    height: 100%;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    color: #{"rgb(var(--gray-500))"};

    transition: all 0.3s;
  }

  &.open &__icon {
    transform: rotate(180deg);
  }

  &.open + .select-box {
    opacity: 1;
    pointer-events: all;
  }
}

.select-box {
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  z-index: 99;
  transform: translateY(100%);

  border-radius: 6px;
  background-color: #fff;

  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))
    drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));

  opacity: 0;
  pointer-events: none;

  transition: all 0.3s;

  &__input-block {
    padding: 4px;
    padding-bottom: 0;

    display: flex;
    flex-direction: column;
  }

  &__list {
    padding: 4px;

    list-style: none;

    display: flex;
    flex-direction: column;
    gap: 2px;

    max-height: 20rem;

    overflow-y: auto;
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    padding: 0.6rem 1rem;
    border-radius: 6px;
    cursor: pointer;

    transition: all 0.3s;

    & p {
      padding: 0 4px;
    }

    &.active,
    &:hover {
      background-color: #{"rgb(var(--muted)/.7)"};
    }
  }
}

.checkbox {
  display: block;
  width: 2.4rem;
  height: 2.4rem;

  position: relative;

  &--sm {
    width: 1.8rem;
    height: 1.8rem;
  }

  & input {
    appearance: none;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 4px;
    border: 2px solid #{"rgb(var(--muted))"};
    cursor: pointer;
    background-color: transparent;
    transition: all 0.3s;

    outline: 2px solid transparent;
    outline-offset: 2px;

    &:focus {
      outline: 2px solid #{"rgb(var(--muted))"};
    }

    &:hover {
      border: 2px solid #{"rgb(var(--primary-default))"};
    }

    &:checked {
      background-color: #{"rgb(var(--primary-default))"};
      border: 2px solid #{"rgb(var(--primary-default))"};

      & + svg {
        display: block;
      }
    }
  }

  &--sm input {
    width: 1.8rem;
    height: 1.8rem;
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.8rem;
    height: 1.8rem;
    stroke: #fff;
    pointer-events: none;

    display: none;
  }

  &--sm svg {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.toggle {
  display: block;
  width: 4rem;
  height: 2.4rem;

  position: relative;

  & input {
    appearance: none;
    width: 4rem;
    height: 2.4rem;
    border-radius: 10rem;
    background-color: #{"rgb(var(--muted)/.7)"};
    backdrop-filter: blur(8px);
    cursor: pointer;

    transition: all 0.3s;

    outline: 2px solid transparent;
    outline-offset: 2px;

    &:focus {
      outline: 2px solid #{"rgb(var(--muted))"};
    }

    &:checked {
      background-color: #{"rgb(var(--primary-default))"};

      & + span {
        left: calc(4rem - 1.8rem - 3px);

        & svg {
          opacity: 1;
        }
      }
    }
  }

  & span {
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;

    background-color: #fff;

    transition: all 0.2s;

    & svg {
      display: block;
      width: 1rem;
      height: 1rem;
      color: #000;

      opacity: 0;
    }
  }
}

.radio {
  appearance: none;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.4rem;
  height: 2.4rem;

  border-radius: 50%;
  border: 2px solid #{"rgb(var(--muted))"};

  outline: 2px solid transparent;
  outline-offset: 2px;

  transition: all 0.3s;

  &--sm {
    width: 1.8rem;
    height: 1.8rem;
  }

  &::after {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #fff;
  }

  &:focus {
    outline: 2px solid #{"rgb(var(--muted))"};
  }

  &:hover {
    border: 2px solid #{"rgb(var(--primary-default))"};
  }

  &:checked {
    background-color: #{"rgb(var(--primary-default))"};
    border: 2px solid #{"rgb(var(--primary-default))"};

    &::after {
      opacity: 1;
    }
  }
}

.box-radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;

  padding: 2.4rem 1.6rem;
  border-radius: 6px;
  cursor: pointer;
  border: 2px solid #{"rgb(var(--muted))"};

  font-weight: 500;

  &:hover {
    border: 2px solid #{"rgb(var(--primary-default))"};
  }

  &__input {
    display: none;
  }

  &__icon {
    display: block;
    width: 2rem;
    height: 2rem;
    color: #{"rgb(var(--primary-default))"};

    opacity: 0;
  }

  &__input:checked + &__icon {
    opacity: 1;
  }
}

.file {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.file-input {
  display: flex;
  align-items: center;

  border-radius: 6px;
  border: 2px solid #{"rgb(var(--gray-200))"};
  overflow: hidden;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
  }

  & input {
    display: none;
    pointer-events: none;
  }

  &__left {
    flex: 1;

    padding: 2rem 2.4rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    font-weight: 500;
    font-size: 1.6rem;

    & svg {
      display: block;
      height: 4.8rem;
    }

    &:hover {
      background-color: #{"rgb(var(--gray-50))"};
    }

    @media (max-width: 600px) {
      justify-content: center !important;
    }
  }

  &.active &__left {
    justify-content: left;
  }

  &__right {
    flex-shrink: 0;

    padding: 0 2rem;

    display: flex;
    align-items: center;
    gap: 1.8rem;

    @media (max-width: 600px) {
      padding: 2rem;
      justify-content: center;
    }

    @media (max-width: 450px) {
      & > * {
        flex: 1;
      }
    }
  }
}

.file-previews {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 1.6rem;

  @media (max-width: 330px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}

.file-preview {
  &__header {
    position: relative;
    height: 160px;
    background-color: #{"rgb(var(--gray-50))"};
    border-radius: 6px;
    cursor: pointer;
  }

  &--media &__header {
    & img,
    & video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: block;
    width: 8rem;
    height: 8rem;

    color: #{"rgb(var(--muted-foreground))"};
  }

  &__remove {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    border: none;
    background-color: #{"rgb(var(--gray-700)/.7)"};
    opacity: 0.2;
    cursor: pointer;

    transition: all 0.3s;

    &:hover {
      background-color: #{"rgb(var(--red-dark))"};
    }

    & ion-icon {
      display: block;
      width: 1.4rem;
      height: 1.4rem;
      color: #fff;
    }
  }

  &__header:hover &__remove {
    opacity: 1;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 3px;

    padding-top: 6px;
    font-size: 1.2rem;
  }

  &__name {
    font-weight: 500;
    color: #{"rgb(var(--gray-700))"};
    overflow-wrap: break-word;
  }

  &__size {
    font-family: monospace;
  }
}

.tags {
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
}

.tag {
  font-weight: 500;
  color: #{"rgb(var(--gray-700))"};
  border: 1px solid #{"rgb(var(--gray-300))"};
  padding: 4px 12px;
  padding-right: 8px;
  border-radius: 10rem;

  display: flex;
  align-items: center;
  gap: 6px;

  &--1 {
    padding-right: 12px;
  }

  & span {
    cursor: pointer;
    color: #{"rgb(var(--gray-500))"};

    &:hover {
      color: #{"rgb(var(--gray-900))"};
    }

    & ion-icon {
      display: block;
      width: 18px;
      height: 18px;
      color: currentColor;
    }
  }
}

.date {
  position: relative;

  & input {
    padding-left: 4.4rem;
    padding-right: 4.4rem;
  }

  &__icon-block {
    position: absolute;
    width: 4.4rem;
    top: 0;
    height: 100%;
    z-index: 9;

    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background-color: transparent;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: #{"rgb(var(--gray-500))"};

      transition: all 0.3s;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  .open &__arrow {
    transform: rotate(180deg);
  }
}

.date-block {
  position: absolute;
  bottom: -8px;
  left: 50%;
  width: 31.5rem;
  max-width: calc(100vw - 4.8rem);
  transform: translate(-50%, 100%);
  z-index: 9;

  padding: 1.6rem;
  border-radius: 6px;
  background-color: #fff;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))
    drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));

  opacity: 0;
  pointer-events: none;

  transition: all 0.3s;

  .open & {
    opacity: 1;
    pointer-events: all;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1.2rem;
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.2rem;
    height: 2.2rem;

    border: 1px solid #{"rgb(var(--gray-300))"};
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  &__heading {
    display: flex;
    align-items: center;

    height: 2.2rem;

    border: none;
    padding: 0 6px;
    border-radius: 6px;
    cursor: pointer;
    background-color: transparent;

    color: #000;
    font-family: "Lexend", sans-serif;
    font-weight: 500;

    transition: all 0.3s;

    &:hover {
      background-color: #{"rgb(var(--gray-100))"};
    }
  }
}

.date-table {
  width: 100%;
  border-collapse: collapse;

  & tr {
    border-bottom: 6px solid transparent;
  }

  & th,
  & td {
    text-align: center;
  }

  & th {
    color: #{"rgb(var(--gray-700))"};
    font-family: "Lexend", sans-serif;
    font-weight: 500;
  }
}

.date-select {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  font-family: inherit;
  cursor: pointer;

  color: #{"rgb(var(--gray-700))"};

  transition: all 0.3s;

  &--faint {
    color: #{"rgb(var(--gray-500))"};
  }

  &--active {
    background-color: #{"rgb(var(--gray-900))"};
    color: #{"rgb(var(--gray-0))"};
  }

  &--today {
    border: 1px solid #{"rgb(var(--gray-300))"};
    color: #{"rgb(var(--gray-900))"};

    &:hover {
      border: 1px solid #{"rgb(var(--gray-600))"};
    }
  }

  &:hover {
    color: #{"rgb(var(--gray-900))"};
    background-color: #{"rgb(var(--gray-200))"};
  }
}

.ym-select {
  height: 19.5rem;

  display: flex;
  gap: 4px;

  &__block {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 8px;

    overflow-y: auto;

    padding: 0 2.4rem;

    &::-webkit-scrollbar {
      display: none;
    }

    & button {
      font-family: inherit;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 4px;
      border-radius: 4px;

      transition: all 0.3s;

      &:hover {
        color: #{"rgb(var(--gray-900))"};
        background-color: #{"rgb(var(--gray-100))"};
      }
    }
  }

  &__divider {
    flex-shrink: 0;

    width: 1px;
    background-color: #{"rgb(var(--muted))"};
  }
}

.quantity {
  position: relative;

  & input {
    padding-right: 7.6rem;
  }

  &__controls {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    display: flex;
    gap: 6px;

    padding: 8px;
  }

  &__control {
    border: 1px solid #{"rgb(var(--muted))"};
    background-color: transparent;
    padding: 0 4px;
    border-radius: 4px;

    &:hover {
      border: 1px solid #{"rgb(var(--primary-default))"};
    }

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }

    &:hover ion-icon {
      color: #{"rgb(var(--primary-dark))"};
    }
  }
}

.telephone {
  & .select-input {
    display: none;
  }

  // & .select-box__input-block {
  //   display: none;
  // }

  &__list-item {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    font-size: 1.3rem;

    & img {
      display: block;
      width: 1.4rem;
      height: 1.4rem;
    }

    & span {
      color: #6b6b6b;
      font-size: 1.2rem;
    }
  }
}

.telephone-input {
  position: relative;

  & input {
    padding-left: 5.2rem;
  }

  &__toggle-country-select {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    display: flex;
    align-items: center;
    gap: 4px;

    cursor: pointer;
    padding: 0 1.2rem;
    border: none;
    background-color: transparent;

    & img {
      display: block;
      height: 1.4rem;
      width: 1.4rem;
    }

    & ion-icon {
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      color: #{"rgb(var(--gray-500))"};
    }
  }
}
