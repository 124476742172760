.pagination {
  display: flex;
  align-items: center;
  gap: 3px;

  & button {
    height: 2.8rem;
    min-width: 2.8rem;
    padding: 0 6px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    font-family: inherit;
    border: none;
    color: #{"rgb(var(--gray-500))"};

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }

    &.active {
      color: #fff;
      background-color: #{"rgb(var(--primary-default))"};
    }
  }
}

.division {
  color: #{"rgb(var(--gray-500))"};
}

.pagination-2 {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  & button {
    display: flex;
    align-items: center;
    gap: 4px;

    height: 4rem;
    padding: 0 1.8rem;
    border-radius: 0.8rem;
    border: none;
    background-color: transparent;
    border: 1px solid #{"rgb(var(--muted))"};
    cursor: pointer;

    color: #{"rgb(var(--gray-500))"};
    font-family: inherit;
    font-size: inherit;

    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.8rem;
      background-color: #{"rgb(var(--gray-100))"};
      z-index: -1;

      transform: scale(0);

      transition: all 0.3s;
    }

    &:hover::after {
      transform: scale(1);
    }

    &.active::after {
      transform: scale(1);
    }

    &:disabled {
      cursor: not-allowed;

      &::after {
        transform: scale(0) !important;
      }

      color: #{"rgb(var(--gray-400))"} !important;
      opacity: 0.9;
    }
  }
}
