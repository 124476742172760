.invoice-main {
  padding: 0 2.4rem 2.4rem;

  margin-top: 4px;
}

.invoice-details {
  padding: 3.2rem;
  border: 1px solid #{"rgb(var(--muted))"};
  border-radius: 1.2rem;

  display: flex;
  flex-direction: column;
  gap: 4.8rem;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }

  &__logo {
    display: block;
    height: 2.8rem;
  }

  &__header-details {
    @media (max-width: 750px) {
      order: -1;
    }
  }

  &__number {
    margin-top: 8px;

    font-family: "Lexend", sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #{"rgb(var(--gray-900))"};
  }

  &__addresses {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.4rem;

    @media (max-width: 650px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 550px) {
      grid-template-columns: 1fr;
    }
  }

  &__address-heading {
    font-family: "Lexend", sans-serif;
    color: #{"rgb(var(--gray-900))"};
    font-weight: 600;
    font-size: 1.6rem;

    margin-bottom: 1.4rem;
  }

  &__address-body {
    display: flex;
    flex-direction: column;
    gap: 6px;

    margin-bottom: 3.2rem;

    & h3 {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.4rem;
    }
  }

  &__date-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__date-heading {
    font-weight: 600;
    font-size: 1.4rem;
  }

  &__qr-block {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 750px) {
      justify-content: flex-start;
      padding-top: 2.4rem;
    }
  }

  &__footer {
    border-top: 1px solid #{"rgb(var(--muted))"};
    padding-top: 3.2rem;

    display: flex;
    justify-content: space-between;
    gap: 2.4rem;

    @media (max-width: 850px) {
      & > * {
        flex: 1;
      }
    }

    @media (max-width: 550px) {
      flex-direction: column;
    }
  }

  &__notes {
    max-width: 45rem;

    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 550px) {
      order: 2;
    }
  }

  &__notes-heading {
    font-size: 1.4rem;
    font-weight: 500;
    color: #{"rgb(var(--gray-900))"};
    font-family: "Lexend", sans-serif;
  }

  &__notes-text {
    line-height: 1.7;
  }
}
