/**
 * ==============================================
 * Preloader
 * ==============================================
 */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2.4rem;
}

.preloader__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;

  width: 35rem;
  max-width: 100%;
}

.preloader__logo {
  display: block;
  height: 4rem;
}

/**
 * ==============================================
 * Modals
 * ==============================================
 */
.success-modal {
  background-color: #fff;
  width: 40rem;
  max-width: 95%;
  max-height: 80vh;
  border-radius: 4px;

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  z-index: 99999;

  padding: 4.8rem 2.4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 400px) {
  .success-modal {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    width: 100%;
  }
}

@keyframes slideUp {
  0% {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }

  100% {
    bottom: initial;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.success-modal.open {
  animation-name: slideUp;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.success-modal h3 {
  font-size: 2.4rem;
  font-weight: 600;
  color: #000;
}

.success-modal__btns {
  align-self: stretch;

  margin-top: 2.4rem;

  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.success-modal__btns button {
  flex: 1;
  height: 4.8rem;
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}

.check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
}

.check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

.check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

.check-icon::before,
.check-icon::after {
  content: "";
  height: 100px;
  position: absolute;
  background: #ffffff;
  transform: rotate(-45deg);
}

.icon-line {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

.icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

.icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}

.icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #ffffff;
}

.icon {
  width: 80px;
  height: 80px;
  border: 4px solid gray;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  transform: scale(1);
}

.icon.warning {
  border-color: #f8bb86;
}

.icon.warning .body {
  position: absolute;
  width: 5px;
  height: 36px;
  left: 50%;
  top: 16px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #f8bb86;
}

.icon.warning .dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 16px;
  background-color: #f8bb86;
}

/**
 * ==============================================
 * Loaders
 * ==============================================
 */
.loaderVBars-container {
  height: 4.2rem;
}

@keyframes loaderVBars {
  0% {
    top: 0.8rem;
    height: 6.4rem;
  }
  50%,
  100% {
    top: 2.4rem;
    height: 3.2rem;
  }
}

.loaderVBars {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
}
.loaderVBars--sm {
  transform: scale(0.5) translateY(-50%);
  height: 4rem;
}
.loaderVBars div {
  display: inline-block;
  position: absolute;
  left: 0.8rem;
  width: 1.6rem;
  background: rgb(var(--primary-default));
  animation: loaderVBars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loaderVBars div:nth-child(1) {
  left: 0.8rem;
  animation-delay: -0.24s;
}
.loaderVBars div:nth-child(2) {
  left: 3.2rem;
  animation-delay: -0.12s;
}
.loaderVBars div:nth-child(3) {
  left: 5.6rem;
  animation-delay: 0;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(var(--primary-default));
  color: rgb(var(--primary-default));
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(var(--primary-default));
  color: rgb(var(--primary-default));
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

/**
 * ==============================================
 * Dot Floating
 * ==============================================
 */
.dot-floating {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(var(--primary-default));
  color: rgb(var(--primary-default));
  animation: dot-floating 3s infinite cubic-bezier(0.15, 0.6, 0.9, 0.1);
}
.dot-floating::before,
.dot-floating::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-floating::before {
  left: -12px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(var(--primary-default));
  color: rgb(var(--primary-default));
  animation: dot-floating-before 3s infinite ease-in-out;
}
.dot-floating::after {
  left: -24px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(var(--primary-default));
  color: rgb(var(--primary-default));
  animation: dot-floating-after 3s infinite cubic-bezier(0.4, 0, 1, 1);
}

@keyframes dot-floating {
  0% {
    left: calc(-50% - 5px);
  }
  75% {
    left: calc(50% + 105px);
  }
  100% {
    left: calc(50% + 105px);
  }
}
@keyframes dot-floating-before {
  0% {
    left: -50px;
  }
  50% {
    left: -12px;
  }
  75% {
    left: -50px;
  }
  100% {
    left: -50px;
  }
}
@keyframes dot-floating-after {
  0% {
    left: -100px;
  }
  50% {
    left: -24px;
  }
  75% {
    left: -100px;
  }
  100% {
    left: -100px;
  }
}

/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(var(--primary-default));
  color: rgb(var(--primary-default));
  box-shadow: 9999px 0 0 0 rgb(var(--primary-default));
  animation: dot-falling 1s infinite linear;
  animation-delay: 0.1s;
}
.dot-falling::before,
.dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(var(--primary-default));
  color: rgb(var(--primary-default));
  animation: dot-falling-before 1s infinite linear;
  animation-delay: 0s;
}
.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(var(--primary-default));
  color: rgb(var(--primary-default));
  animation: dot-falling-after 1s infinite linear;
  animation-delay: 0.2s;
}

@keyframes dot-falling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 rgb(var(--primary-default));
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 rgb(var(--primary-default));
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 rgb(var(--primary-default));
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
