.roles {
  padding: 0 2.4rem 2.4rem;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.4rem;

  @media (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
}

.role {
  padding: 2.4rem;
  border-radius: 0.8rem;
  border: 1px solid #{"rgb(var(--muted))"};

  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &__header {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__icon-block {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 4rem;
    height: 4rem;
    border-radius: 0.8rem;

    & svg {
      display: block;
      width: 2.4rem;
      height: 2.4rem;

      color: #fff;
    }
  }

  &__heading {
    font-weight: 500;
    font-size: 2rem;
    color: #{"rgb(var(--gray-900))"};
    font-family: "Lexend", sans-serif;
  }

  &__menu-wrapper {
    margin-left: auto;

    position: relative;
  }

  &__menu-toggle {
    cursor: pointer;

    display: flex;
    padding: 0;
    border: none;
    background-color: transparent;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &__menu {
    position: absolute;
    bottom: -1.6rem;
    right: 0;
    width: 20rem;

    transform: translateY(100%);

    padding: 6px;
    border: 1px solid #{"rgb(var(--muted))"};
    background-color: #{"rgb(var(--background))"};
    border-radius: 6px;
    z-index: 9;

    display: flex;
    flex-direction: column;
    gap: 2px;

    opacity: 0;
    pointer-events: none;

    .open & {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__menu-item {
    display: flex;
    flex-direction: column;

    & button {
      padding: 6px 1.2rem;
      cursor: pointer;
      font-family: inherit;
      background-color: transparent;
      border: none;
      border-radius: 4px;
      color: #{"rgb(var(--gray-500))"};

      display: flex;
      align-items: flex-start;

      transition: all 0.3s;

      &:hover {
        background-color: #{"rgb(var(--muted)/.7)"};
        color: #{"rgb(var(--gray-600))"};
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    margin-bottom: 4px;
  }

  &__users {
    display: flex;
  }

  &__user {
    display: flex;
    border: 2px solid #fff;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: -5px;
    }
  }

  &__user-img {
    display: block;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
  }
}
