/*
* Line Loader
*/

.line-loader {
  display: block;
  width: 100%;
  height: 4px;
  position: relative;
  background-color: #{"rgb(var(--primary-lighter))"};
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #{"rgb(var(--primary-dark))"};
    animation: loaderLine 3s cubic-bezier(0.09, 0.89, 0.7, 0.71) infinite;
  }
}
