.hidden {
  display: none !important;
}

.text-right {
  text-align: right !important;
}

.align-right {
  justify-content: flex-end;
}

.text-danger {
  color: #{"rgb(var(--red-default))"};
}

.f-justify-start {
  justify-content: flex-start !important;
}

.w-100 {
  width: 100% !important;
}

.link {
  color: #{"rgb(var(--blue-default))"};
  font-weight: 600;
  cursor: pointer;

  &:link,
  &:visited {
    color: #{"rgb(var(--blue-default))"};
    font-weight: 600;
  }

  //   &:hover,
  //   &:active {
  //   }
}

.text-link {
  cursor: pointer;
  color: #{"rgb(var(--blue-default))"};
}

.mb-medium {
  margin-bottom: 3.6rem;
}

.mt-medium {
  margin-top: 3.6rem;
}

.mt-md {
  margin-top: 4rem;
}

.mb-small {
  margin-bottom: 2.4rem;
}

.mt-small {
  margin-top: 2.4rem;
}

.grid-full {
  grid-column: 1 / -1;
}

.align-center {
  align-items: center !important;
}

.p-small {
  padding: 2.4rem;
}

.flex {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.grow {
  flex: 1;
}

.noshrink {
  flex-shrink: 0;
}

.ml-auto {
  margin-left: auto;
}

.flex-full {
  flex: 1;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.text-center {
  text-align: center;
}

.fill-muted {
  fill: #{"rgb(var(--muted) / 1)"};
}

.fill-muted\/70 {
  fill: #{"rgb(var(--muted) / .7)"};
}

.fill-background {
  fill: #{"rgb(var(--background) / 1)"};
}
