.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;

  display: block;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  cursor: pointer;

  opacity: 0;
  pointer-events: none;

  transition: all 0.2s;

  .modal-open & {
    opacity: 1;
    pointer-events: all;
  }
}

.role-group-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;

  height: 100%;
  overflow-y: auto;

  padding: 6rem 2.4rem;

  display: flex;
  align-items: flex-start;

  &__main {
    width: 70rem;
    margin: auto;
    max-width: 100%;
    background-color: #fff;
    border-radius: 1.2rem;
    padding: 2.4rem;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    font-size: 2rem;
    font-weight: 600;
    color: #{"rgb(var(--gray-900))"};
    font-family: "Lexend", sans-serif;
  }

  &__close {
    cursor: pointer;
    background-color: transparent;
    border: none;

    display: flex;
    align-items: center;
    justify-content: baseline;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__role-access {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }

  &__sub-heading {
    font-size: 1.6rem;
    font-weight: 600;
    color: #{"rgb(var(--gray-900))"};
    font-family: "Lexend", sans-serif;
    margin-bottom: 6px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.6rem;
  }

  &__action-group {
    display: grid;
    grid-template-columns: 25fr 75fr;
    gap: 3.6rem;

    padding-bottom: 1.6rem;
    border-bottom: 1px solid #{"rgb(var(--gray-200))"};

    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
    }
  }

  &__action-group-name {
    font-size: 1.4rem;
    font-weight: 500;
    color: #{"rgb(var(--gray-700))"};
    font-family: "Lexend", sans-serif;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1.6rem;

    @media (max-width: 500px) {
      justify-content: flex-start;
    }
  }

  &__action {
    padding: 0.8rem 1.4rem;
    border-radius: 0.6rem;
    border: 2px solid #{"rgb(var(--muted))"};
    outline: 1px solid #{"rgb(var(--muted))"};
    outline-offset: -2px;
    background-color: transparent;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    min-width: 9rem;
    height: 4rem;
    color: inherit;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    transition: all 0.3s;

    &:hover {
      outline: 1px solid #{"rgb(var(--primary-default))"};
    }

    & svg {
      display: none;
    }

    &.active {
      outline: 2px solid #{"rgb(var(--primary-default))"};
    }

    &.active svg {
      display: block;
    }
  }
}
