.btn {
  flex-shrink: 0;

  height: 4rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;

  padding: 0 2.4rem;
  border: none;
  border-radius: 6px;
  background-color: #{"rgb(var(--primary-default))"};
  cursor: pointer;

  color: #fff;
  font-weight: 500;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;

  transition: all 0.3s;

  outline: 2px solid transparent;
  outline-offset: 2px;

  &:hover {
    background-color: #{"rgb(var(--primary-dark))"};
  }

  &:focus {
    outline: 2px solid #{"rgb(var(--muted))"};
  }

  &:disabled {
    opacity: 0.6;
  }

  &--dark {
    background-color: #000;

    &:hover {
      background-color: #000;
    }
  }

  &--sm {
    height: 3.2rem;
    font-size: 1.2rem;
    padding: 0 1.2rem;
  }

  &--md {
    height: 4.8rem;
  }

  & ion-icon,
  & svg {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
  }
}

.button {
  flex-shrink: 0;

  height: 4rem;
  padding: 0 1.2rem;
  border-radius: 6px;
  cursor: pointer;

  border: 1px solid #{"rgb(var(--muted))"};
  background-color: transparent;

  font-weight: 500;
  font-family: inherit;
  font-size: inherit;
  color: inherit;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  transition: all 0.3s;

  outline: 2px solid transparent;
  outline-offset: 2px;

  &:focus {
    outline: 2px solid #{"rgb(var(--muted))"};
  }

  &:hover {
    border: 1px solid #{"rgb(var(--primary-default))"};
    color: #{"rgb(var(--primary-default))"};
  }

  &.active {
    border: 1px dashed #{"rgb(var(--gray-700))"} !important;

    &:hover {
      border: 1px dashed #{"rgb(var(--primary-default))"} !important;
    }
  }

  & svg,
  & ion-icon {
    display: block;
    height: 1.8rem;
    width: 1.8rem;
    color: currentColor;
  }
}

.action-btn {
  flex-shrink: 0;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  height: 4.4rem;
  border-radius: 5px;
  padding: 0 1.2rem;

  border: none;
  background-color: #{"rgb(var(--muted))"};
  backdrop-filter: blur(8px);
  cursor: pointer;
  font-family: inherit;
  font-weight: 500;
  color: #{"rgb(var(--gray-500))"};

  margin-bottom: 0.4rem;

  &--fixed {
    width: 3.6rem;
    height: 3.6rem;
    padding: 0;
    border-radius: 3px;
  }

  & ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    color: inherit;
  }

  &--fixed ion-icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  &:hover {
    background-color: #{"rgb(var(--primary-lighter))"};
    color: #{"rgb(var(--primary-dark))"};
  }
}

.text-btn {
  display: inline-flex;
  align-items: center;
  gap: 6px;

  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;

  &--1 {
    font-weight: 500;
    text-decoration: none;
    font-size: 1.4rem;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &--danger {
    color: #{"rgb(var(--red-default))"};
  }
}
