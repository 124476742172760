@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

html {
  font-size: 62.5%;

  @media (max-width: 768px) {
    font-size: 55%;
  }

  @media (max-width: 450px) {
    font-size: 50%;
  }
}

body {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: "Inter", sans-serif;

  line-height: 1.5;
  color: #{"rgb(var(--gray-600))"};

  &.modal-open {
    overflow: hidden;
  }
}
