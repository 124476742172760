.badge {
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 6px;
  padding: 6px 10px;

  &--rounded {
    border-radius: 10rem;
  }

  &--success {
    color: #{"rgb(var(--green-dark))"};
    background-color: #{"rgb(var(--green-lighter))"};
  }
}
