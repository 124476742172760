.header {
  height: 7.2rem;

  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.02), 0 2px 6px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(24px);
  background-color: #{"rgb(var(--gray-0)/.8)"};
  z-index: 999;

  position: sticky;
  top: 0;
  width: 100%;
  padding: 0 2.4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: all 0.3s;

  .modal-open & {
    position: relative;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
    gap: 1.8rem;

    & > * {
      flex-shrink: 0;
    }
  }

  &__sidebar-toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;

    display: none;

    @media (max-width: 1150px) {
      display: block;
    }

    & svg {
      display: block;
      height: 2.8rem;
    }
  }

  &__logo {
    display: block;
    height: 2.8rem;

    display: none;

    @media (max-width: 1150px) {
      display: block;
    }
  }

  &__user {
    position: relative;
  }

  &__user-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:active {
      transform: translateY(1px);
    }

    & img {
      display: block;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
    }
  }
}

.user-menu {
  position: absolute;
  bottom: 0;
  right: 0;
  bottom: -6px;
  transform: translateY(100%);

  width: 26rem;

  background-color: #{"rgb(var(--background))"};
  border: 1px solid #{"rgb(var(--muted))"};
  border-radius: 6px;

  opacity: 0;
  pointer-events: none;

  transition: all 0.3s;

  &.open {
    opacity: 1;
    pointer-events: all;
  }

  & > *:not(:last-child) {
    border-bottom: 1px solid #{"rgb(var(--gray-300))"};
  }

  &__user {
    padding: 2.4rem;

    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__user-img {
    display: block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }

  &__user-main {
    display: flex;
    flex-direction: column;
  }

  &__user-name {
    font-weight: 600;
    font-size: 1.6rem;
    font-family: "Lexend", sans-serif;
    color: #{"rgb(var(--gray-900))"};
  }

  &__user-email {
    color: #{"rgb(var(--gray-600))"};
  }

  &__links {
    padding: 1.4rem;

    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  &__link {
    &:link,
    &:visited {
      display: block;
      padding: 0.8rem 1rem;
      text-decoration: none;
      font-weight: 500;
      color: #{"rgb(var(--gray-700))"};
      border-radius: 6px;

      transition: all 0.3s;
    }

    &:hover,
    &:active {
      background-color: #{"rgb(var(--gray-100))"};
      color: #{"rgb(var(--gray-900))"};
    }

    &.active {
      color: #{"rgb(var(--primary-default))"} !important;
    }
  }

  &__sign-out {
    &:link,
    &:visited {
      text-decoration: none;
      display: block;
      padding: 2.4rem;
      font-weight: 500;
      color: #{"rgb(var(--gray-700))"};
      border-radius: 6px;
    }

    &:hover,
    &:active {
      color: #{"rgb(var(--gray-900))"};
    }
  }
}

// header search
.hsearch {
  height: 4rem;
  flex: 0 0 40rem;
  padding: 6px;
  padding-left: 1.6rem;
  border-radius: 0.8rem;
  cursor: pointer;
  border: 2px solid #{"rgb(var(--muted))"};

  display: flex;
  align-items: center;
  gap: 0.8rem;

  &:hover {
    border: 2px solid #{"rgb(var(--gray-900))"};
  }

  @media (max-width: 750px) {
    flex: initial;
    gap: 1.6rem;
  }

  & ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
  }

  & p {
    flex: 1;

    color: #{"rgb(var(--gray-600))"};

    @media (max-width: 750px) {
      display: none;
    }
  }

  &:hover p {
    color: #{"rgb(var(--gray-900))"};
  }

  & button {
    align-self: stretch;
    padding: 0 0.8rem;
    background-color: #{"rgb(var(--primary-default))"};
    color: #{"rgb(var(--primary-foreground))"};
    font-family: inherit;
    border: none;
    border-radius: 6px;
    font-size: 1.2rem;
    font-weight: 600;
  }
}
