.table-block {
  border: 1px solid #{"rgb(var(--muted))"};
  border-radius: 1.2rem;
  overflow: hidden;

  &__header {
    padding: 2.4rem;
    padding-bottom: 1.6rem;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__footer {
    padding: 2.4rem;
  }
}

// come back to
.table-block-1 {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  padding: 0 2.4rem 2.4rem;
}

.table-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;

  @media (max-width: 400px) {
    flex-direction: column;
  }
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

.th {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  text-transform: uppercase;
  color: #{"rgb(var(--gray-500))"};
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.2rem;

  &--right {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }

  & span {
    cursor: pointer;

    & ion-icon {
      display: block;
    }
  }
}

.table-header {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  &__heading {
    font-size: 2rem;
    color: #{"rgb(var(--gray-900))"};
    font-family: "Lexend", sans-serif;
  }
}

.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 1.8rem;
  }
}

.tuser {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  &__img {
    display: block;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }

  &--sm &__img {
    width: 3.2rem;
    height: 3.2rem;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__name {
    font-weight: 500;
    color: #111111;
    font-family: "Lexend", sans-serif;
  }

  &__email {
    color: #{"rgb(var(--gray-500))"};
    font-size: 1.3rem;
  }
}

.titem {
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 1.2rem;

  & svg {
    display: block;
    height: 2.4rem;
    stroke: #{"rgb(var(--gray-600))"};
  }
}

.tdate {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__date {
    font-weight: 500;
  }

  &__time {
    font-size: 1.3rem;
    color: #{"rgb(var(--gray-500))"};
  }
}

.tbadge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 15rem;
  font-size: 1.25rem;
  padding: 4px 12px;
  border-radius: 10rem;
  font-weight: 500;
  font-family: "Lexend", sans-serif;

  &--success {
    border: 1px solid #{"rgb(var(--green-default))"};
    color: #{"rgb(var(--green-dark))"};
  }

  &--failed {
    border: 1px solid #{"rgb(var(--red-default))"};
    color: #{"rgb(var(--red-dark))"};
  }

  &--info {
    border: 1px solid #{"rgb(var(--blue-default))"};
    color: #{"rgb(var(--blue-dark))"};
  }

  &--pending {
    border: 1px solid #{"rgb(var(--secondary-default))"};
    color: #{"rgb(var(--secondary-dark))"};
  }
}

.tstatus {
  display: flex;
  align-items: center;
  gap: 6px;

  font-size: 1.3rem;
  font-weight: 600;

  &::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &--pending {
    color: #{"rgb(var(--orange-dark))"};

    &::before {
      background-color: #{"rgb(var(--orange-default))"};
    }
  }

  &--success {
    color: #{"rgb(var(--green-dark))"};

    &::before {
      background-color: #{"rgb(var(--green-default))"};
    }
  }

  &--failed {
    color: #{"rgb(var(--red-default))"};

    &::before {
      background-color: #{"rgb(var(--red-default))"};
    }
  }

  &--default {
    color: #{"rgb(var(--gray-600))"};

    &::before {
      background-color: #{"rgb(var(--gray-400))"};
    }
  }
}

.tactions {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1.6rem;
}

.taction {
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 7px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #{"rgb(var(--muted))"};
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;

  &:hover {
    border: 1px solid #{"rgb(var(--primary-default))"};
  }

  & ion-icon {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    color: #{"rgb(var(--gray-600))"};
  }
}

a.taction {
  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;
  }
}

.tinfo {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  &__img {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    object-fit: fill;
    border-radius: 6px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__heading {
    font-size: 1.4rem;
    font-weight: 500;
    color: #{"rgb(var(--gray-900))"};
    font-family: "Lexend", sans-serif;
  }

  &__desc {
    max-width: 25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #{"rgb(var(--gray-500))"};
    font-weight: 400;
  }
}

.tempty {
  padding: 3.2rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;

  &__img {
    display: block;
    width: 18rem;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  & thead tr {
    background-color: #{"rgb(var(--gray-100))"};
  }

  &--no-border thead tr {
    & th:nth-child(1) {
      border-radius: 6px 0px 0px 6px;
    }

    & th:nth-last-child(1) {
      border-radius: 0px 6px 6px 0px;
    }
  }

  & tbody tr {
    transition: all 0.3s;

    &:hover {
      background-color: #{"rgb(var(--gray-50))"};
    }

    &.no-hover:hover {
      background-color: transparent;
    }
  }

  & tbody tr:not(:last-child) {
    border-bottom: 1px solid #{"rgb(var(--muted) / 0.7)"};
  }

  &--no-border tbody tr:not(:last-child) {
    border-bottom: none;
  }

  & th,
  & td {
    white-space: nowrap;

    &:first-child {
      padding-left: 2.4rem !important;
    }

    &:last-child {
      padding-right: 2.4rem !important;
    }
  }

  & th {
    padding: 1.2rem;
  }

  & td {
    padding: 1.8rem 1.2rem;
  }
}

.tflex {
  display: flex;
  align-items: center;
  gap: 6px;

  & ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
  }
}

.tfilter {
  position: fixed;
  top: 0;
  right: 0;

  transform: translateX(100%);

  width: 400px;
  max-width: 80%;
  height: 100vh;

  z-index: 99999;

  background-color: #{"rgb(var(--gray-0))"};

  display: flex;
  flex-direction: column;

  transition: all 0.3s;

  &.open {
    transform: translateX(0);
  }

  &__header {
    padding: 1.6rem 2rem;
    border-bottom: 1px solid #{"rgb(var(--muted))"};

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    font-weight: 700;
    font-size: 2rem;
    font-family: "Lexend", sans-serif;
    color: #{"rgb(var(--gray-900))"};

    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__close {
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &__body {
    flex: 1;

    padding: 1.6rem 2rem;

    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;

    padding: 1.6rem 2rem;
  }
}

.trating {
  display: flex;
  align-items: baseline;
  gap: 0.8rem;

  &__stars {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__star {
    display: block;
    color: #{"rgb(var(--gray-300))"};
    visibility: visible;

    &.active {
      color: #{"rgb(var(--orange-default))"};
    }
  }
}

.tstock {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__progress {
    width: 10rem;
    height: 6px;
    border-radius: 10rem;

    position: relative;

    & div {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 10rem;
    }

    &--out {
      background-color: #{"rgb(var(--red-default)/.2)"};
    }

    &--low {
      background-color: #{"rgb(var(--orange-default)/.2)"};

      & div {
        background-color: #{"rgb(var(--orange-default))"};
      }
    }

    &--ok {
      background-color: #{"rgb(var(--green-default)/.2)"};

      & div {
        background-color: #{"rgb(var(--green-default))"};
      }
    }
  }

  &__text {
    font-size: 1.3rem;
    color: #{"rgb(var(--gray-500))"};
  }
}

.tselect-action {
  margin-top: 1.2rem;

  padding: 1.4rem 2rem;
  background-color: #{"rgb(var(--gray-0))"};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 99;

  position: sticky;
  bottom: 0;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;

  .modal-open & {
    position: relative;
  }

  &::before {
    content: "";
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border: 1px solid #{"rgb(var(--gray-300))"};
    border-radius: 8px;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  @media (max-width: 360px) {
    flex-direction: column;
  }
}

.summary-table {
  border-spacing: 0 15px;

  width: 35rem;
  max-width: 100%;

  &--bordered {
    border-collapse: collapse;
    border-spacing: initial;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  & td {
    padding: 0;
  }

  & td:last-child {
    text-align: right;
    font-weight: 600;
  }

  & tr:last-child td {
    font-size: 1.8rem;
    font-weight: initial;
  }

  &--bordered tr {
    &:not(:last-child) {
      border-bottom: 1px solid #{"rgb(var(--muted))"};
    }

    & td {
      padding: 2rem 0;
    }

    &:first-child {
      & td {
        padding-top: 0;
      }
    }

    &:last-child {
      & td {
        padding-bottom: 0;
      }
    }
  }
}

.texpand {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;

  border: 1px solid #{"rgb(var(--muted))"};
  background-color: transparent;
  cursor: pointer;

  & ion-icon {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    color: #{"rgb(var(--gray-500))"};
    transition: all 0.3s;
  }

  &:hover {
    border: 1px solid #{"rgb(var(--primary-default))"};

    & ion-icon {
      color: #{"rgb(var(--primary-default))"};
    }
  }

  &.open ion-icon {
    transform: rotate(180deg);
  }
}

.suborder-table {
  width: 100%;
  border-collapse: collapse;

  & tbody tr:hover {
    background-color: transparent;
  }

  & tbody tr:not(:last-child) {
    border-bottom: 1px solid #{"rgb(var(--muted) / 0.7)"};
  }

  & td {
    padding: 1.8rem 0 !important;

    &:first-child {
      padding-left: 0 !important;
    }

    &:last-child {
      padding-right: 0 !important;
    }
  }
}

.tquantity {
  display: flex;
  align-items: center;
  gap: 6px;

  & ion-icon {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    color: #{"rgb(var(--gray-500))"};
  }
}

.tproduct {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  &__img {
    display: block;
    width: 8rem;
    height: 6rem;
    object-fit: cover;
    border-radius: 6px;
  }

  &--sm &__img {
    width: 4.8rem;
  }

  &__main {
    display: flex;
    flex-direction: column;
  }

  &__name {
    color: #{"rgb(var(--gray-900))"};
    font-weight: 500;
    font-family: "Lexend", sans-serif;

    margin-bottom: 2px;
  }

  &__category {
    color: #{"rgb(var(--gray-500))"};

    margin-bottom: 4px;
  }

  &__unit-price {
    font-size: 1.2rem;
    color: #{"rgb(var(--gray-500))"};
  }
}
